import '../shared/nav/collapsing_md_nav'
import '../shared/phone'

// import '../shared/nav/side_nav'
// import '../f1/top_nav/'
import PhoneNumber from '../shared/phone.js'
import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import CartNav from '../shared/nav/nav_cart'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import AddToCart from '../full-gpe/cart/add-to-cart'
import VueLoader from '../default/vue-loader'
import PriceFilter from '../shared/price_filter'
import '../shared/seating_chart_filter'
// import './seating_chart'
import { store } from'../default/store'
import '../derby/tooltip'
import '../shared/buyers_page_personalizer'
import '../shared/stripe_checkout'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  Checkout.loadWithJqueryAddOn()
  AddToCart.AddToCart()

  if(document.getElementById('seating_chart_price')) {
    VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'), store)
  }
  let phoneNumber = PhoneNumber()
  $('.navbar-right').append(`<li class="menu-item phone-contact"><a href="tel:${phoneNumber}"><div class="original">Questions? Call Us</div></a></li>`)
  // $('.navbar-right').append('<div class="final-item"></div>')
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)

})
